<script>
export default {
  name: "Url",
  computed: {
    keyId() {
      return this.$route.params.keyId;
    },
  },
  methods: {
    async redirectByShortener() {
      const response = await http.send(`urls/${this.keyId}`, { _method: "GET" });

      if (http.responseAccepted(response)) {
        if (response.data?.original_url) {
          await this.$router.push({
            name: "auctions.bid",
            params: {
              auction: response.data.original_url,
            },
          });
        } else {
          await this.$router.push({ name: "home" });
        }
      } else {
        await this.$router.push({ name: "home" });
      }
    },
  },
  async created() {
    await this.redirectByShortener();
  },
};
</script>

<template>
  <div></div>
</template>
